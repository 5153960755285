import { Component } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'formly-array-type',
  template: `
    <nz-card [nzTitle]="titleTemplate" nzSize="small" [style.borderRadius]="0">
      <ng-template #descriptionTemplate>
        @if (to.description) {
          <markdown>
            {{ to.description }}
          </markdown>
        }
      </ng-template>

      <ng-template #titleTemplate>
        {{ to.label }}

        @if (to.description) {
          <i
            nz-icon
            nzType="info-circle"
            style="color: gray; margin-right: 10px"
            nz-tooltip
            [nzTooltipTitle]="descriptionTemplate"
            [nzTooltipColor]="'dimgray'"
            nzTooltipPlacement="top"
            [nzTooltipMouseEnterDelay]="0.5"></i>
        }
      </ng-template>

      @if (showError && formControl.errors) {
        <div class="alert alert-danger" role="alert">
          <formly-validation-message [field]="field" />
        </div>
      }

      @for (field of field.fieldGroup; track field; let i = $index) {
        <div class="row align-items-center">
          <formly-field class="col" [field]="field" />
        </div>
      }

      <div class="d-flex flex-row-reverse" style="margin-bottom: 10px">
        <button type="button" (click)="add()" nz-button nzSize="small">+</button>
        @if (field.fieldGroup && field.fieldGroup.length > 0) {
          <button type="button" (click)="remove(field.fieldGroup.length - 1)" nz-button nzSize="small">-</button>
        }
      </div>
    </nz-card>
  `,
  styles: [
    `
      button {
        aspect-ratio: 1 /1;
      }
    `
  ],
  standalone: true,
  imports: [NzCardModule, MarkdownModule, NzButtonModule, NzIconModule, NzToolTipModule, FormlyModule, NzWaveModule]
})
export class ArrayTypeComponent extends FieldArrayType {}
