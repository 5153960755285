import { importProvidersFrom } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';

import { ArrayTypeComponent } from './array.type';
import { MultiSchemaTypeComponent } from './multischema.type';
import { ObjectTypeComponent } from './object.type';

export * from './array.type';
export * from './multischema.type';
export * from './object.type';

export const FORMLY_PROVIDERS = importProvidersFrom(
  FormlyModule.forRoot({
    extras: { resetFieldOnHide: true },
    types: [
      { name: 'string', extends: 'input' },
      {
        name: 'number',
        extends: 'input',
        defaultOptions: {
          templateOptions: {
            type: 'number'
          }
        }
      },
      {
        name: 'integer',
        extends: 'input',
        defaultOptions: {
          templateOptions: {
            type: 'number'
          }
        }
      },
      { name: 'boolean', extends: 'checkbox' },
      { name: 'enum', extends: 'select' },
      { name: 'array', component: ArrayTypeComponent },
      { name: 'object', component: ObjectTypeComponent },
      { name: 'multischema', component: MultiSchemaTypeComponent }
    ]
  })
);
